<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>
          <section class="edp-backend-conditionSearch">
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.dtableTitle3") }}
              </div>
              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  :placeholder="$t('edpBackendCommon.placeholder')"
                  v-model="query.templateName"
                  clearable
                />
              </div>
            </div>
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.dtableTitle4") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-select
                  v-model="query.templateType"
                  clearable
                  :placeholder="$t('edpBackendCommon.select')"
                >
                  <el-option
                    v-for="(item, i) in types"
                    :key="i"
                    :label="item.dicName"
                    :value="item.dicCode"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.dtableTitle8") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-select
                  v-model="query.isEnabled"
                  clearable
                  :placeholder="$t('edpBackendCommon.select')"
                >
                  <el-option
                    :label="$t('edpBackendCommon.statusNp')"
                    value="0"
                  ></el-option>
                  <el-option
                    :label="$t('edpBackendCommon.statusYes')"
                    value="1"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div
              class="edp-backend-conditionSearch-square conditionSearch-square-last"
            >
              <el-button size="small" @click="onclear">{{
                $t("edpBackendCommon.clear")
              }}</el-button>
              <el-button size="small" type="primary" @click="getList(1)">{{
                $t("edpBackendCommon.search")
              }}</el-button>
            </div>
          </section>
          <section class="edp-backend-common-table">
            <div class="table-headControlLine">
              <div>
                <el-button type="primary" size="small" @click="onShow(1)">
                  {{ $t("edpBackendCommon.add") }}</el-button
                >
              </div>
            </div>

            <el-table
              stripe
              :data="pageList"
              style="width: 100%"
              v-loading="loading"
            >
              <el-table-column fixed prop="id" label="ID" width="50">
              </el-table-column>

              <el-table-column
                prop="templateName"
                :label="$t('edpBackendEntry.dtableTitle3')"
              >
              </el-table-column>

              <el-table-column
                prop="templateType"
                :label="$t('edpBackendEntry.dtableTitle4')"
              >
                <template slot-scope="scope">
                  <span>{{ filterType(scope.row.templateType) }}</span>
                </template>
              </el-table-column>

              <el-table-column
                prop="isEnabled"
                :label="$t('edpBackendEntry.tableTite5')"
              >
                <template slot-scope="scope">
                  <el-tag
                    :type="scope.row.isEnabled ? 'success' : 'danger'"
                    disable-transitions
                    >{{
                      scope.row.isEnabled
                        ? $t("edpBackendCommon.statusYes")
                        : $t("edpBackendCommon.statusNp")
                    }}</el-tag
                  >
                </template>
              </el-table-column>

              <el-table-column
                prop="modifyTime"
                :label="$t('edpBackendEntry.dtableTitle2')"
              ></el-table-column>

              <el-table-column
                :label="$t('edpBackendCommon.operate')"
                fixed="right"
                width="110"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="onShow(2, scope.row)">
                    {{ $t("edpBackendCommon.edit") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="pageTotal"
            >
            </el-pagination>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>
    <el-dialog
      :title="dialogTitle"
      width="40%"
      :visible.sync="editShow"
      :before-close="onClose"
    >
      <el-form :model="form" label-width="140px" ref="funForm">
        <el-form-item label="ID">
          <el-input v-model="form.id" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item
          prop="templateName"
          :label="$t('edpBackendEntry.dtableTitle3')"
          :rules="[
            { required: true, message: $t('edpBackendCommon.notEmpty') },
          ]"
        >
          <el-input
            v-model="form.templateName"
            autocomplete="off"
            :placeholder="$t('edpBackendCommon.placeholder')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('edpBackendEntry.dtableTitle4')"
          prop="templateType"
          :rules="[
            { required: true, message: $t('edpBackendCommon.notEmpty') },
          ]"
        >
          <el-select
            v-model="form.templateType"
            :placeholder="$t('edpBackendCommon.select')"
            clearable
          >
            <el-option
              v-for="(item, i) in types"
              :key="i"
              :label="item.dicName"
              :value="item.dicCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('edpBackendEntry.dtableTitle5')">
          <el-row>
            <el-col :span="24">
              <el-date-picker
                v-model="times"
                type="daterange"
                style="width: 240px"
                range-separator="~"
                value-format="yyyy-MM-dd HH:mm:ss"
                :start-placeholder="$t('edpBackendCommon.startDate')"
                :end-placeholder="$t('edpBackendCommon.endDate')"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          :label="$t('edpBackendEntry.dtableTitle6')"
          prop="templateExample"
          :rules="[
            { required: true, message: $t('edpBackendCommon.notEmpty') },
          ]"
        >
          <el-select
            v-model="form.templateExample"
            :placeholder="$t('edpBackendCommon.select')"
            clearable
          >
            <el-option
              v-for="(item, i) in methods"
              :key="i"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('edpBackendEntry.dtableTitle7')">
          <el-switch
            v-model="form.isDefault"
            active-color="rgba(97, 191, 180, 1)"
            inactive-color="rgba(0, 0, 0, 0.24705882352941178)"
          >
          </el-switch>
        </el-form-item>
        <el-form-item :label="$t('edpBackendEntry.dtableTitle8')">
          <el-switch
            v-model="form.isEnabled"
            active-color="rgba(97, 191, 180, 1)"
            inactive-color="rgba(0, 0, 0, 0.24705882352941178)"
          >
          </el-switch>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="onClosed">{{
          $t("edpBackendCommon.canale")
        }}</el-button>
        <el-button type="primary" @click="onSave('funForm')">{{
          $t("edpBackendCommon.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "backendFunction",
  data() {
    return {
      query: {
        templateName: "",
        templateType: "",
        isEnabled: "",
      },
      pageList: [],
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      loading: false,
      editShow: false,
      dialogTitle: this.$t("edpBackendCommon.add"),
      form: {
        id: "",
        templateName: "",
        templateType: "",
        templateTypeName: "123",
        templateStartDate: "",
        templateEndDate: "",
        templateExample: "",
        isDefault: false,
        isEnabled: false,
      },
      times: ["", ""],
      types: [],
      methods: [
        { name: "模板一", value: "1" },
        { name: "模板二", value: "2" },
      ],
    };
  },
  watch: {
    times(val) {
      console.log(val);
      if (val) {
        this.form.templateStartDate = val[0];
        this.form.templateEndDate = val[1];
      } else {
        this.form.templateStartDate = "";
        this.form.templateEndDate = "";
      }
    },
  },
  mounted() {
    this.getDict();
    this.getList();
  },
  methods: {
    ...mapActions({
      tempList: "user/tempList",
      addTemp: "user/addTemp",
      editTemp: "user/editTemp",
      getDictValue: "user/getDictValue",
    }),
    async getDict() {
      let res = await this.getDictValue("milestone_report");
      console.log(res);
      this.types = res.data;
    },
    filterType(val) {
      let arr = this.types.filter((e) => e.dicCode == val);
      if (arr.length) {
        return arr[0].dicName;
      } else {
        return "";
      }
    },
    async getList(val) {
      if (val) {
        this.currentPage = val;
      }
      let params = {
        current: this.currentPage,
        size: this.pageSize,
        ...this.query,
      };
      let res = await this.tempList(params);
      console.log(res);
      this.pageList = res.data.records;
      this.currentPage = res.data.current;
      this.pageTotal = res.data.total;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    onclear() {
      this.query = {
        templateName: "",
        templateType: "",
        isEnabled: "",
      };
    },
    onClose(done) {
      for (var key in this.form) {
        if (key === "isDefault" || key === "isEnabled") {
          this.form[key] = false;
        } else {
          this.form[key] = "";
        }
      }
      this.times = ["", ""];
      done();
    },
    onShow(val, data) {
      if (val == 1) {
        this.dialogTitle = this.$t("edpBackendCommon.add");
      } else {
        console.log(data);
        this.dialogTitle = this.$t("edpBackendCommon.edit");
        this.form = JSON.parse(JSON.stringify(data));
        if (data.templateStartDate && data.templateEndDate) {
          this.times = [data.templateStartDate, data.templateEndDate];
        }
      }
      const that = this;
      this.$nextTick(() => {
        that.$refs.funForm.clearValidate();
      });
      this.editShow = true;
    },
    onClosed() {
      this.editShow = false;
      for (var key in this.form) {
        if (key === "isDefault" || key === "isEnabled") {
          this.form[key] = false;
        } else {
          this.form[key] = "";
        }
      }
      this.times = ["", ""];
    },
    async onSave(formName) {
      let res = {};
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (Array.isArray(this.form.menuId)) {
            this.form.menuId = this.form.menuId[0];
          }
          if (this.form.id) {
            res = await this.editTemp(this.form);
          } else {
            res = await this.addTemp(this.form);
          }

          if (res.success) {
            this.onClosed();
            this.getList();
            this.$message({
              type: "success",
              message: this.$t("edpBackendCommon.actionSuccess"),
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
</style>
